import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import '../Common/Tiles/ProductTile.css';
import './Checkout.css';
import Button from '../Common/Button';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { formatPrice } from '../Utilities/Formats';
import defaultImage from '../images/defaultImage.jpg';
import { configData, contentData } from '../Whitelabel/websitesData';
import { baraoHyundaiFilter } from '../Whitelabel/Barao/baraoHyundaiFilter';

import { useDispatch } from 'react-redux';
import { INCREASE_QUANTITY, DECREASE_QUANTITY, REMOVE_FROM_CART } from '../reducers/cart';

function ProductRow({hasSale, ...props}) {
  const dispatch = useDispatch();

  const [itemQty, setItemQty] = useState(props.itemQty);

  const gtmAddToCart = () => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: 'BRL',
                value: props.price, // Optional: total value of the item being added
                items: [{
                    id: props.id,
                    name: props.title,
                    price: props.price,
                    quantity: 1,
                    seller: props.seller,
                    discount: props.discount || ''
                }]
            }
        });
    } else {
        console.warn("dataLayer is not defined");
    }
  };

  const incrementQty = () => {
    // Check if the current item is associated with a seller
    const availableStock = props.stock; // Get the available stock from the seller

    // Check if the current quantity is less than the available stock
    if (itemQty < availableStock) {
        setItemQty(prevQty => prevQty + 1);
        dispatch({
            type: INCREASE_QUANTITY,
            payload: { 
                id: props.id, 
            }
        });
        gtmAddToCart();
    } else {
        // Optionally, show a message that stock is insufficient
        alert(`Não é possível adicionar mais unidades. Estoque: ${availableStock}`);
    }
  };

  const decrementQty = () => {
    if (itemQty > 1) {
      setItemQty(prevQty => prevQty - 1);

      dispatch({
          type: DECREASE_QUANTITY,
          payload: { 
              id: props.id, 
          }
      });
      gtmRemoveFromCart(1);  // Remove 1 item
    } else {
      dispatch({
          type: REMOVE_FROM_CART,
          payload: { 
              id: props.id, 
          }
      });
      gtmRemoveFromCart(itemQty);  // Remove all items of this type
    }
};
  
  const gtmRemoveFromCart = (qty) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                currency: 'BRL',
                remove: {
                    products: [{
                        id: props.id,
                        name: props.title,
                        quantity: qty,
                    }]
                }
            }
        });
    } else {
        console.warn("dataLayer is not defined");
    }
};

  const removeItem = () => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: { 
          id: props.id, 
      }
    });
    gtmRemoveFromCart(itemQty);
  }

  return (
    <div className='row center' style={{gap: '10px'}}>
      <div className='column center'>
        <Button
          className='buttonStyle buttonColorTransparentDark tinyButton'
          icon={<AddIcon/>}
          onClick={incrementQty}
        />
        <p style={{margin: '4px auto'}}>{itemQty}</p>
        <Button
          className='buttonStyle buttonColorTransparentDark tinyButton redText'
          icon={<RemoveIcon/>}
          onClick={decrementQty}
        />
      </div>
      <div className='checkoutItemInfo clickArea' style={{justifyContent: 'space-between', padding: '10px 10px 10px 0px'}} onClick={props.onClick}>

        <div className='row center' style={{gap: '6px'}}>
          {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(props.id)
            ?
              <img src={contentData.missingPic} className='productRowImg'/>
            :
              <img src={props.img ? props.img : defaultImage} className='productRowImg'/>
          }
          <div className='column '>
              <h3 className='' translate="no">
                {props.title} {props.titleComplement}
              </h3>
              {/* <h4 className='tileDescription'>
                  {props.description && Object.entries(props.description).map(([key, value], index) => (
                      <p key={index}>
                          <span>{key}: </span>
                          <span>{value}</span>
                      </p>
                  ))}
              </h4> */}
              {/* <h3 className='grayedText'>Código: {props.id}
                {props.description && Object.entries(props.description).map(([key, value], index) => (
                  <span key={index}>, {key}: {value}</span>
                ))}</h3> */}
              <h3 className='grayedText noSelect'>Código: {props.id}</h3>
              <div className='row' style={{gap: '6px'}}>
                {hasSale &&
                  <p className='originalPrice'>{formatPrice(props.originalPrice*itemQty)}</p>
                }
                <p className='mainPrice' style={{fontWeight: '100'}}>{formatPrice(props.price*itemQty)}</p>
              </div>
          </div>
        </div>
      </div>
      <Button
          className='mainButtonColor buttonColorLight smallButton grayedText buttonLabelAnim'
          label='remover'
          iconStart={<DeleteIcon/>}
          onClick={removeItem}
        />
    </div>
  );
}

// Define PropTypes
ProductRow.propTypes = {
  hasSale: PropTypes.bool
};

// Set default props
ProductRow.defaultProps = {
  hasSale: false  // Default value if not provided
};

export default ProductRow;