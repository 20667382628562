import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import '../App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications
import Button from '../Common/Button';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { OrderPH } from '../Utilities/Placeholders';
import CopyIcon from "@mui/icons-material/ContentCopy";
import qrcode from '../images/qrcode_placeholder.webp';
import { formatPrice, formatCep } from '../Utilities/Formats';
import { cardOperatorLogos } from '../Utilities/Placeholders';
import { CreditCard } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { getMktName } from '../Providers/Sellers'; // Import the getMktName function
import { configData, contentData } from '../Whitelabel/websitesData';
import { baraoHyundaiFilter } from '../Whitelabel/Barao/baraoHyundaiFilter';

function ConfirmationPage () {
    const { result } = useParams();
    const location = useLocation();
    const orderdata = location.state?.orderdata.data || location.state?.orderdata;
    const purchaseData = location.state?.purchaseData;
    const paymentType = orderdata?.p_type || orderdata?.type || '';
    const qrCodeBase64 = orderdata?.qr_code || '';
    const pixCode = orderdata?.link || '';
    const payDoc = purchaseData?.payment?.doc || purchaseData?.payment?.card?.clientDoc || '';
    const payPhone = purchaseData?.payment?.phone || purchaseData?.payment?.card?.phone || '';
    const navigate = useNavigate();
    const navigateTo = (page) => {
        navigate(page);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [mktNames, setMktNames] = useState({}); // State to hold market names

    useEffect(() => {
        // Set a timer to change isLoading to false after 1000ms
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (purchaseData && purchaseData.products) {
            const fetchMktNames = async () => {
                const names = {};
                for (const product of purchaseData.products) {
                    const sellerName = product.sellerInfo.name;
                    const mktName = await getMktName(sellerName);
                    names[sellerName] = mktName; // Store the market name by seller name
                }
                setMktNames(names); // Update state with all market names
            };

            fetchMktNames();
        }
    }, [purchaseData]);

    const handleShare = async () => {
        try {
            //console.log('Attempting to copy PIX code:', pixCode); // Debugging log
    
            if (pixCode) {
                // Check if Clipboard API is available
                if (navigator.clipboard && navigator.clipboard.writeText) {
                    await navigator.clipboard.writeText(pixCode);
                    toast.success('Código PIX copiado.');
                } else {
                    // Fallback for older browsers or if Clipboard API is not available
                    const textArea = document.createElement("textarea");
                    textArea.value = pixCode;
                    document.body.appendChild(textArea);
                    textArea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textArea);
                    toast.success('Código PIX copiado.');
                }
            } else {
                console.error('PIX code is not defined or empty'); // Debugging log
                toast.error('Código PIX não está disponível');
            }
        } catch (error) {
            console.error('Erro ao copiar código PIX:', error);
            toast.error('Falha ao copiar código PIX :(');
        }
    };

    //console.log('purchaseData: ', purchaseData);
    //console.log('confirmResults: ', orderdata);

    return (
        <div className='main fadeIn'>

            {isLoading
                ?
                    <div className='column center' style={{height: '50vh'}}>
                        <CircularProgress
                            style={{ padding: '20px' }}
                            sx={{
                                color: 'var(--secondColor)',
                            }}
                        />
                    </div>
                :
                    <>
                        {result === 'pgto-realizado' &&
                            <div className='purchaseConfirmContainer'>
                                <CheckCircleOutline className='confirmCircle'/>
                                <h1>Pedido gerado :)</h1>
                                

                                <div className='purchaseSummaryContainer'>

                                    {paymentType === 'pix' &&
                                        <div className='column' style={{gap: 'var(--smallRowGap)'}}>
                                            {/* <h1>Realize o pagamento pelo PIX:</h1> */}
                                            <span>Para concluir, realize o pagamento via PIX:</span>
                                            <div className='pixCodeContainer'>

                                                <div className='column center'>
                                                    <div className='row center'>
                                                        <span className='grayedText'>Valor: <span className='textColor'>{formatPrice(purchaseData.payment.purchasePrice || '')}</span></span>
                                                    </div>
                                                    {qrCodeBase64 && (
                                                        <img 
                                                            alt='Pix QR Code' 
                                                            src={`data:image/png;base64,${qrCodeBase64}`} 
                                                            className='pixQrCode'
                                                        />
                                                    )}
                                                    <span className='pixCode'>{pixCode}</span>
                                                </div>

                                                <div className='row center'>
                                                    <Button
                                                        className='buttonColorGray smallButton'
                                                        label='Copiar código PIX'
                                                        iconStart={<CopyIcon style={{fontSize: '18px'}}/>}
                                                        onClick={handleShare}
                                                    />
                                                    <ToastContainer
                                                        position="top-center" 
                                                        autoClose={2000} // Automatically fades away after 2 seconds
                                                        hideProgressBar={true} // No countdown
                                                        closeOnClick 
                                                        // pauseOnHover 
                                                        // draggable 
                                                        pauseOnFocusLoss 
                                                        style={{ marginTop: '60px', zIndex: 999 }} // Top margin and z-index
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {paymentType === 'credit' &&
                                        <div className='column' style={{gap: 'var(--smallRowGap)'}}>
                                            <span>Resumo do pagamento:</span>
                                            <div className='column defaultBorderedItem' style={{gap: 'var(--mediumRowGap)'}}>
                                                <span className='grayedText'>Total: <span className='textColor'>{formatPrice(orderdata.value)}</span></span>
                                                <span className='grayedText'>Parcelas: <span className='textColor'>{orderdata.parcels}x de {formatPrice(orderdata.value / orderdata.parcels)}</span></span>
                                                <div className='row' style={{ alignItems: 'center', gap: '10px' }}>
                                                    <span><span className='grayedText'>Cartão: •••• </span>{orderdata.lastDigits} </span>
                                                    {orderdata.brand
                                                    ? 
                                                        <img src={cardOperatorLogos[orderdata.brand.toLowerCase()]}  alt={`${orderdata.brand} logo`} style={{height: '24px'}}/>
                                                    :
                                                        <CreditCard style={{color: 'gray'}}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* <div className='column' style={{gap: 'var(--mediumRowGap)'}}>
                                        <span>Dados do pagador:</span>
                                        <div className='column defaultBorderedItem' style={{gap: '10px', padding: 'var(--mediumPadding)'}}>
                                            <span>Documento: <span className='textColor'>{purchaseData.payment.doc}</span></span>
                                            <span>Telefone: <span className='textColor'>{purchaseData.payment.phone}</span></span>
                                        </div>
                                    </div> */}
                                    
                                {purchaseData && purchaseData.products && (
                                    <div className='column' style={{ gap: 'var(--smallRowGap)' }}>
                                        <span>Sua compra:</span>
                                        <div className='column' style={{ gap: '10px' }}>
                                            {purchaseData.products.map((product) => {
                                                const shippingMethod = purchaseData.shipping[product.sellerInfo.name];
                                                const sellerMktName = mktNames[product.sellerInfo.name] || 'N/A'; // Get the market name

                                                return (
                                                    <div className='column defaultBorderedItem' style={{ gap: 'var(--mediumRowGap)' }} key={product.id}>
                                                        <div className='row' style={{ gap: '4px', alignItems: 'center' }}>
                                                            <span>{product.itemQty}x</span>
                                                            {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(product.id)
                                                            ?
                                                                <img src={contentData.missingPic} className='confirmationProductImg' />
                                                            :
                                                                <img src={product.img} className='confirmationProductImg' />
                                                            }
                                                            <span>{product.title}</span>
                                                        </div>
                                                        <div className='column' style={{ gap: 'var(--mediumRowGap)' }}>
                                                            <span className='grayedText'>
                                                                Loja: <span className='textColor'>{(configData.defaultStore === 'GoParts' || configData.defaultStore === 'Pampa') ? sellerMktName : product.sellerInfo.name}</span>
                                                            </span>
                                                            {shippingMethod === 'pickup' ? (
                                                                <span className='grayedText'>Opção de envio: <mark>Retirar na loja</mark></span>
                                                            ) : (
                                                                <span className='grayedText'>Endereço de entrega: <span className='textColor'>{purchaseData.clientAddress.street}, {purchaseData.clientAddress.number} {purchaseData.clientAddress.complement !== '' ? ` - ${purchaseData.clientAddress.complement}` : ''}, {purchaseData.clientAddress.district}, {purchaseData.clientAddress.city} - {purchaseData.clientAddress.state} - {formatCep(purchaseData.clientAddress.zipCode)}</span></span>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}

                                    <div className='column' style={{gap: 'var(--smallRowGap)'}}>
                                        <span>Informações adicionais do comprador:</span>
                                        <div className='column defaultBorderedItem' style={{gap: 'var(--mediumRowGap)', padding: 'var(--mediumPadding)'}}>
                                            <span className='grayedText'>Documento: <span className='textColor'>{payDoc}</span></span>
                                            <span className='grayedText'>Telefone: <span className='textColor'>{payPhone}</span></span>
                                            {/* <span className='grayedText'>Total: <span className='textColor'>{formatPrice(purchaseData.payment.purchasePrice)}</span></span>
                                            <span className='grayedText'>Forma de pagamento: <span className='textColor'>
                                                {orderdata.p_type  === 'credit' &&
                                                    <span>Cartão de crédito ({orderdata.parcels}x de {formatPrice(orderdata.value / orderdata.parcels)})</span>
                                                }
                                                {orderdata.p_type  === 'pix' && 'PIX'}
                                            </span></span> */}
                                        </div>
                                    </div>

                                </div>

                                {/* {Object.entries(orderdata).map(([key, value]) => (
                                    <span key={key}>
                                        {key}: {
                                            // Handle different types of values
                                            typeof value === 'object' 
                                                ? JSON.stringify(value)
                                                : value.toString()
                                        }
                                    </span>
                                ))}
                                {Object.entries(purchaseData).map(([key, value]) => (
                                    <span key={key}>
                                        {key}: {
                                            // Handle different types of values
                                            typeof value === 'object' 
                                                ? JSON.stringify(value)
                                                : value.toString()
                                        }
                                    </span>
                                ))} */}
                                <span style={{textAlign: 'center'}}>Seus produtos serão encaminhados para envio (ou retirada) após o processamento do pagamento. Acompanhe o status do seu pedido em "Pedidos" ou pelo seu email.</span>
                                <Button
                                    className='buttonColorMain smallButton'
                                    label='Ver meus pedidos'
                                    onClick={() => navigateTo('/minha-conta#pedidos')}
                                />
                            </div>
                        }

                        {result === 'erro' &&
                            <div className='column' style={{maxWidth: '640px', margin: 'auto', paddingInline: '20px', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                                <CheckCircleOutline style={{fontSize: '128px', color: 'var(--highlightGreen)'}}/>
                                <h1>Ops! Falha ao processar o pagamento :(</h1>
                                    {paymentType === 'credit'
                                    ?
                                        <span style={{textAlign: 'center'}}>O pagamento com o cartão de crédito informado não foi realizado. Tente novamente ou entre em contato com o banco do cartão de crédito.</span>
                                    :
                                        <span style={{textAlign: 'center'}}>Erro ao gerar o código PIX. Tente novamente ou entre em contato com a GoParts.</span>
                                    }
                                <Button
                                    className='buttonColorMain smallButton'
                                    label='Início'
                                    onClick={() => navigateTo('/')}
                                />
                            </div>
                        }

                        {result === '' || result === 'undefined' &&
                            <div className='column' style={{maxWidth: '640px', margin: 'auto', paddingInline: '20px', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                                <ErrorOutline style={{fontSize: '128px', color: 'gray'}}/>
                                <h1>Ops!</h1>
                                <span style={{textAlign: 'center'}}>Página não encontrada.</span>
                                <Button
                                    className='buttonColorMain smallButton'
                                    label='Início'
                                    onClick={() => navigateTo('/')}
                                />
                            </div>
                        }

                        {/* <div className='pageContent'>
                            {paymentType === 'pix'
                                ?
                                    <div className='column' style={{maxWidth: '640px', margin: 'auto', paddingInline: '20px', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                                        <h1>PIX para pagamento:</h1>
                                        <img alt='Pix QR Code' src={qrcode} className='pixQrCode'/>
                                        <span className='pixCode'>{pixCode}</span>
                                        <Button
                                            className='buttonColorThird smallButton'
                                            label='Copiar código PIX'
                                            iconStart={<CopyIcon style={{fontSize: '18px'}}/>}
                                            onClick={handleShare}
                                        />
                                        <span style={{textAlign: 'center'}}>Seus produtos serão encaminhados para envio (ou retirada) após o processamento do pagamento. Acompanhe o status do seu pedido em "Pedidos" ou pelo seu email.</span>
                                        <Button
                                            className='buttonColorMain smallButton'
                                            label='Ver meus pedidos'
                                            onClick={() => navigateTo('/minha-conta#pedidos')}
                                        />
                                    </div>
                                :
                                    <div className='column' style={{maxWidth: '640px', margin: 'auto', paddingInline: '20px', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                                        <CheckCircleOutline style={{fontSize: '128px', color: 'var(--highlightGreen)'}}/>
                                        <h1>Pedido realizado! :)</h1>
                                        <span style={{textAlign: 'center'}}>Seus produtos serão encaminhados para envio (ou retirada) após o processamento do pagamento. Acompanhe o status do seu pedido em "Pedidos" ou pelo seu email.</span>
                                        <Button
                                            className='buttonColorMain smallButton'
                                            label='Ver meus pedidos'
                                            onClick={() => navigateTo('/minha-conta#pedidos')}
                                        />
                                    </div>
                            }
                        </div> */}
                    </>
            }
        </div>
    )
}

export default ConfirmationPage;
