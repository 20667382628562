import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import HeaderNavigation from './HeaderNavigation';
import Logo from '../Common/Logo';
import Search from './Search';
import { contentData, configData } from '../Whitelabel/websitesData';


function Header() {
  const [hasText, setHasText] = useState(false);

  return (
    <div className='headerBackground'>
      <div className="headerContainer">
        <NavLink to='/'>
          <Logo
            type={configData.defaultStore === 'GoParts'
              ? 'mainLogoWhite'
              : 'mainLogo'
            }
            class='headerLogo'
          />
        </NavLink>
        <NavLink to='/' className={`${!hasText ? 'visible' : 'notVisible'}`}>
          <Logo
            type={configData.defaultStore === 'GoParts'
              ? 'bannerLogoWhite'
              : 'squareLogo'
            }
            class='headerLogo'
          />
        </NavLink>
        {/* {!(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') &&
          <Search setHasText={setHasText}/>
        } */}
        <Search setHasText={setHasText}/>
        <div className={`${!hasText ? 'visible' : 'notVisible'}`}>
          <HeaderNavigation />
        </div>
      </div>
    </div>
  );
}

export default Header;
