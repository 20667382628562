import React from 'react';
import ReactDOM from 'react-dom/client'; // Change this line
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import rootReducer from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { UserProvider } from './Providers/UserContext.js'

const store = createStore(rootReducer, applyMiddleware(thunk));

const root = ReactDOM.createRoot(document.getElementById('root')); // Create root
root.render(
    <React.StrictMode>
      <UserProvider>
        <Provider store={store}>
          <Router>
              <App />
          </Router>
        </Provider>
      </UserProvider>
    </React.StrictMode>
);

reportWebVitals();
