import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../reducers/vehicle';
import '../App.css';
import './Header.css';
import AutoComplete from './AutoComplete';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import VehicleFilter from './VehicleFilter';
import { contentData, configData } from '../Whitelabel/websitesData';

function Search({ setHasText }) {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setHasText(value !== '');
  };

  const clearInput = () => {
    setInputValue('');
    setHasText(false);
    document.getElementById('search').focus();
  };

  const openVehicleSelect = (type, data) => {
    if (type === 'brand') {
      dispatch(setSelectedBrand(''));
      dispatch(setSelectedYear(''));
      dispatch(setSelectedModel(''));
      dispatch(setSelectedVersion(''));
      dispatch(setSelectedBrand(data.toLowerCase()));
      dispatch(setIsSelectingVehicle(true));
      clearInput();
    }
  };
  
  const searchPlaceholder = selectedVersion 
    // ? `"${selectedModel} ${selectedYear} ${selectedVersion}"`
    ? 'Nome ou código da peça...'
    : 'Código da peça...';

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        const value = event.target.value;
        navigate(`/busca/${encodeURIComponent(value)}`);
        setInputValue(''); // Clear input after navigation
        setHasText(false);
    }
  };

  // const handleFocus = () => {
  //   if ((configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos') && !selectedVersion) {
  //     dispatch(setIsSelectingVehicle(true));
  //   }
  // };

  return (
    <form className={`searchContainer ${inputValue ? 'focused' : 'notFocused'}`}>
      <input
        id='search'
        type='text'
        placeholder={searchPlaceholder}
        // onFocus={handleFocus}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={inputValue}
        className='searchInput'
        autoComplete="off"
      />
      {/* {inputValue &&
        <div className='row searchButtons'>
          <div className='clearSearchButton' onClick={clearInput}>
            <CloseIcon style={{ color: 'var(--mainColor)' }} />
          </div>
        </div>
      } */}

      <div className='row searchButtons'>
        {inputValue &&
            <div className='clearSearchButton' onClick={clearInput}>
              <CloseIcon style={{ color: 'var(--mainColor)' }} />
            </div>
        }
        {(configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos') &&
          <VehicleFilter className='vehicleFilterButtonPosition'/>
        }
      </div>

      {/* {inputValue && (configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos') && (
        <AutoComplete
          searchValue={inputValue}
          clearAutocomplete={clearInput}
          onAutoCompleteClick={openVehicleSelect}
        />
      )} */}
    </form>
  );
}

export default Search;
