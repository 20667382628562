import React from 'react';
import squareLogo from '../images/g_main_logo_01_lightblue.png';
import bannerLogoWhite from '../images/GoParts_Logo_23Q1_reduzida_brancoCor.png';
import mainLogo from '../images/logo_goparts_main_color_lightblue.png';
import mainLogoWhite from '../images/logo_goparts_main_white.png';
import mainLogoLight from '../images/GoParts_Logo_24_principal_light.png';
import squareLogoLight from '../images/GoParts_Logo_24_reduzida_light.png';
import { contentData, configData } from '../Whitelabel/websitesData';

function Logo(props) {
  const type = props.type;
  return (
    <>
    {type == 'squareLogo' &&
      <img className={`${props.class} squareLogo`} src={contentData.squareLogo} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
    }
    {type == 'bannerLogoWhite' &&
      <img className={`${props.class} squareLogo`} src={contentData.bannerLogoWhite} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
    }
    {type == 'mainLogo' &&
      <img className={`${props.class} mainLogo`} src={contentData.mainLogo} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
    }
    {type == 'mainLogoWhite' &&
      <img className={`${props.class} mainLogo`} src={contentData.mainLogoWhite} alt={`${configData.defaultStore} logo`} loading='lazy'/>
    }
    </>
  );
}

export default Logo;
