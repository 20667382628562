import React, { useState } from 'react';
import '../App.css';
import Button from '../Common/Button';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { createUser } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import PasswordToggle from '../Common/PasswordToggle';
import { CircularProgress } from '@mui/material';
import { useUser } from '../Providers/UserContext';
import GPUser from '../models/gpuser'; // Correct import for default export
import { getFirestore, doc, getDoc } from 'firebase/firestore';

// Helper function to format CEP
const formatCepForDisplay = (rawCep) => {
  if (!rawCep) return '';
  
  const digits = rawCep.replace(/\D/g, '');
  if (digits.length >= 5) {
    return digits.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3');
  } else if (digits.length >= 2) {
    return digits.replace(/^(\d{2})(\d{3})?/, '$1.$2');
  }
  return digits;
};

function Signup() {
  const navigate = useNavigate();
  const { gpuser, setUser } = useUser(); // Moved this to the top level of the component
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const destination = queryParams.get('destination');
  
  const [fullName, setFullname] = useState('');
  const [cep, setCep] = useState(localStorage.getItem('userCep') || '');
  const [displayCep, setDisplayCep] = useState(formatCepForDisplay(localStorage.getItem('userCep') || ''));
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [clientDoc, setClientDoc] = useState('');
  const [isClientDocValid, setIsClientDocValid] = useState(true);

  const [visibility, setVisibility] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const toggleVisibility = (field) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleFullname = (event) => {
    const value = event.target.value;
    // Regular expression to allow letters (including accented) and spaces
    const regex = /^[A-Za-zÀ-ÿ\s]*$/;

    // Check if the input matches the regex
    if (regex.test(value)) {
        setFullname(value);
    }
  };

  const handleCep = (event) => {
    // Remove non-digits and limit to 8 characters
    const rawValue = event.target.value.replace(/\D/g, '').slice(0, 8);
    
    // Store raw value
    setCep(rawValue);
    
    // Update formatted display value
    setDisplayCep(formatCepForDisplay(rawValue));
  };

  const handleEmail = (event) => {
    const value = event.target.value.replace(/\s/g, ''); // Remove all spaces
    setEmail(value);
  };

  const handlePassword = (event) => {
    const value = event.target.value.replace(/\s/g, ''); // Remove all spaces
    setPassword(value);
  };

  const handleCPassword = (event) => {
    const value = event.target.value.replace(/\s/g, ''); // Remove all spaces
    setCPassword(value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    let formattedValue = value;
  
    if (value.length > 2 && value.length <= 6) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2)}`;
    } else if (value.length > 6 && value.length <= 10) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2, 6)}-${value.slice(6)}`;
    } else if (value.length > 10) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2, 7)}-${value.slice(7, 11)}`;
    }
  
    setPhone(formattedValue);
    const isValid = validatePhone(formattedValue);
    setIsPhoneValid(isValid);
    //console.log('Client Phone Valid:', isValid);
  };

  const validatePhone = (phone) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    return cleanedPhone.length === 10 || cleanedPhone.length === 11;
  };

  const handleClientDocChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
  
    if (value.length > 14) value = value.slice(0, 14);
  
    let formattedValue = value;
  
    if (value.length <= 11) {
      formattedValue = value.replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      formattedValue = value.replace(/(\d{2})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1/$2')
                            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }
  
    setClientDoc(formattedValue);
    const isValid = validateClientDoc(formattedValue);
    setIsClientDocValid(isValid);
    //console.log('Client Doc Valid:', isValid);
  };

  const validateClientDoc = (doc) => {
    const cleanedDoc = doc.replace(/\D/g, '');
    return cleanedDoc.length === 11 || cleanedDoc.length === 14;
  };

  let newCPF = '';
  let newCNPJ = '';

  const defineDoc = (doc) => {
    // Remove non-numeric characters
    const cleanedDoc = doc.replace(/\D/g, ''); // \D matches any non-digit character

    
    if (cleanedDoc.length === 11) {
        newCPF = doc; // If doc is CPF
    } else if (cleanedDoc.length === 14) {
        newCNPJ = doc; // If doc is CNPJ
    }

    return { newCPF, newCNPJ }; // Return the values
  };

  const gtmSignup = (email) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'sign_up',
        email: email,
      });
    }
  };

  const register = async (e) => {
    e.preventDefault();
    const isValid = validateFields();
    if (isValid) {
      setIsLoading(true);
      try {
        const firebaseResponse = await createUserWithEmailAndPassword(auth, email, password);

        defineDoc(clientDoc);

        const userInfo = {
          uid: firebaseResponse.user.uid,
          email,
          name: fullName,
          cep,
          phone,
          cpf: newCPF,
          cnpj: newCNPJ,
        };

        localStorage.setItem('userCep', cep);

        const teste = await createUser(userInfo);

        if (Object.keys(teste).length !== 0) {
          const gpUser = new GPUser({
            id: userInfo.uid,
            email: userInfo.email,
            telefone: userInfo.phone,
            cpf: userInfo.cpf,
            cnpj: userInfo.cnpj,
            name: userInfo.name,
            cep: userInfo.cep,
          });
          gpUser.saveData();
          gtmSignup(gpUser.email);
          const userDocRef = gpUser.firestoreRef;
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const loadedUser = await GPUser.fromDocument(userDoc);
            setUser(loadedUser);

            // Add saved address if it exists
            const savedAddress = localStorage.getItem('tempAddress');
            if (savedAddress) {
              try {
                const parsedAddress = JSON.parse(savedAddress);
                loadedUser.setAddress(parsedAddress);
              } catch (error) {
                console.error('Error setting saved address:', error);
              }
            }
          }
          navigate(destination || '/minha-conta');
        }
      } catch (error) {
        setIsLoading(false);
        handleRegisterError(error);
      }
    }
  };

  const handleRegisterError = (error) => {
    if (error.code === 'auth/email-already-in-use') {
      const userConfirmed = window.confirm(
        `Já existe uma conta com o email ${email}. Deseja recuperar a senha desta conta?`
      );

      if (userConfirmed) {
        navigate('/recuperar-senha');
      }
    } else {
      alert('Erro ao criar a nova conta. Tente novamente mais tarde.');
    }
    console.error(error);
  };

  const validateFields = () => {
    // Check if any field is empty
    if (!fullName.trim()) {
      alert("Por favor, preencha seu nome completo");
      return false;
    }

    if (!clientDoc) {
      alert("Por favor, preencha seu CPF ou CNPJ");
      return false;
    }

    if (!phone) {
      alert("Por favor, preencha seu telefone");
      return false;
    }

    if (!cep) {
      alert("Por favor, preencha seu CEP");
      return false;
    }

    if (!email) {
      alert("Por favor, preencha seu email");
      return false;
    }

    if (!password || !cpassword) {
      alert("Por favor, preencha sua senha e confirmação");
      return false;
    }

    // Validate CEP format
    if (cep.replace(/\D/g, '').length !== 8) {
      alert("CEP inválido");
      return false;
    }

    if (!isValidEmail(email)) {
      alert("Email inválido");
      return false;
    }

    if (password !== cpassword) {
      alert("As senhas devem ser iguais");
      return false;
    }

    if (password.length < 6) {
      alert("A senha deve ter pelo menos 6 caracteres");
      return false;
    }

    if (!isPhoneValid) {
      alert("Telefone inválido");
      return false;
    }

    if (!isClientDocValid) {
      alert("CPF/CNPJ inválido");
      return false;
    }

    return true;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="main fadeIn">
      {isLoading ? (
        <div className="pageContent center column">
          <CircularProgress style={{ padding: '20px' }} sx={{ color: 'var(--mainColor)' }} />
        </div>
      ) : (
        <div className="pageContent column" style={{ alignItems: 'center', gap: '10px' }}>
          <div className="card">
            <div className="formContainer">
              <form className="column" style={{ padding: '20px', gap: '10px' }} onSubmit={register}>
                <h2>Nova conta</h2>
                <input 
                  type="text" 
                  placeholder="nome completo *" 
                  value={fullName} 
                  onChange={handleFullname}
                  required 
                />
                <input 
                  type="text" 
                  placeholder="CPF ou CNPJ *" 
                  value={clientDoc} 
                  onChange={handleClientDocChange}
                  required 
                />
                <input 
                  type="text" 
                  placeholder="telefone *" 
                  value={phone} 
                  onChange={handlePhoneChange}
                  required 
                />
                <input 
                  type="text" 
                  placeholder="CEP do seu endereço *" 
                  value={displayCep} 
                  onChange={handleCep}
                  required 
                />
                <input 
                  type="email" 
                  placeholder="email *" 
                  value={email} 
                  onChange={handleEmail}
                  required 
                />
                <div className="formFieldRow">
                  <input 
                    type={visibility.newPassword ? 'text' : 'password'} 
                    placeholder="senha *" 
                    value={password} 
                    onChange={handlePassword}
                    required 
                    minLength={6}
                  />
                  <PasswordToggle isVisible={visibility.newPassword} onClick={() => toggleVisibility('newPassword')} />
                </div>
                <div className="formFieldRow">
                  <input 
                    type={visibility.confirmPassword ? 'text' : 'password'} 
                    placeholder="confirmar senha *" 
                    value={cpassword} 
                    onChange={handleCPassword}
                    required 
                    minLength={6}
                  />
                  <PasswordToggle isVisible={visibility.confirmPassword} onClick={() => toggleVisibility('confirmPassword')} />
                </div>
                <Button className="buttonStyle buttonColorMain largeButton" label="Criar" type='submit' />
                <div className="column center">
                  <NavLink to="/login">Voltar</NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Signup;
