import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/keyboard'; // Import the keyboard module
import { FreeMode, Navigation, Pagination, Thumbs, Keyboard } from 'swiper/modules';

export default function ProductImgSwiper(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [zoomVisible, setZoomVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active image index
    const mainSwiperRef = useRef(null); // Ref for the main Swiper

    const toggleZoom = () => {
        setZoomVisible(!zoomVisible);
    };

    const handleThumbClick = (index) => {
        if (mainSwiperRef.current) {
            mainSwiperRef.current.slideTo(index); // Change the active slide
            setActiveIndex(index); // Update the active index
        }
    };

    return (
        <>
            <Swiper
                onSwiper={(swiper) => {
                    mainSwiperRef.current = swiper; // Set the ref to the Swiper instance
                }}
                loop={true}
                spaceBetween={10}
                modules={[FreeMode, Navigation, Pagination, Thumbs]} // No keyboard module here
                navigation={true}
                pagination={{ clickable: true }}
                thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : null}
                className="productPageSwiper"
            >
                {props.images && props.images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img 
                            src={image} 
                            alt={image} 
                            className='productPageImg' 
                            onClick={() => { setActiveIndex(index); toggleZoom(); }} 
                            loading='lazy' 
                            onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                            draggable="false" // Disable dragging
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            {props.images && props.images.length > 1 && (
                <Swiper
                    onSwiper={(swiper) => {
                        setThumbsSwiper(swiper);
                    }}
                    spaceBetween={50}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="productPageSwiperThumbs"
                >
                    {props.images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img 
                                src={image} 
                                alt={image} 
                                className='productPageImgThumb' 
                                loading='lazy' 
                                onClick={() => handleThumbClick(index)} // Handle thumbnail click
                                onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                                draggable="false" // Disable dragging
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            {/* Zoom Swiper */}
            <Swiper
                loop={true}
                spaceBetween={0}
                modules={[FreeMode, Navigation, Pagination, Thumbs, Keyboard]} // Include Keyboard module here
                navigation={true} // Ensure navigation buttons are included
                thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : null}
                keyboard={{ enabled: true }} // Always enable keyboard navigation
                className={`productPageSwiperZoom ${zoomVisible ? 'openZoom' : ''} fadeIn`}
                onClick={toggleZoom}
            >
                {props.images && props.images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div className='center' style={{ height: '100%' }}>
                            <img src={image} alt={image} className='productPageImgZoom' loading='lazy' 
                                onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                                draggable="false" // Disable dragging
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}