import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import '../../Home/Home.css';
import './Slider.css';
import './ProductSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/swiper-bundle.css';
import ProductTile from '../Tiles/ProductTile';
import { firestore } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { configData } from '../../Whitelabel/websitesData';
import Product from '../Classes/Product';
import CircularProgress from '@mui/material/CircularProgress';

import tabKits from '../../images/categories/manutencaobasica_500px.webp';
import tabOleos from '../../images/categories/lubrificantes_e_fluidos_500px.webp';
import tabFiltros from '../../images/categories/filtros_500px.webp';
import tabAcessorios from '../../images/categories/tab_acessorio.png';
import tabRoupas from '../../images/categories/boutique_500px.webp';
import tabCorreias from '../../images/categories/correiastensoreserolamentos_500px.webp';
import tabMotores from '../../images/categories/motoreperifericos_500px.webp';
import tabFreios from '../../images/categories/freios_500px.webp';
import tabLuzes from '../../images/categories/iluminacao_500px.webp';
import tabRelated from '../../images/categories/allCats.webp';
import tabAll from '../../images/categories/shop_500px.webp';

const tabImages = {
    'tabKits': tabKits,
    'tabOleos': tabOleos,
    'tabFiltros': tabFiltros,
    'tabAcessorios': tabAcessorios,
    'tabRoupas': tabRoupas,
    'tabCorreias': tabCorreias,
    'tabMotores': tabMotores,
    'tabFreios': tabFreios,
    'tabLuzes': tabLuzes,
    'tabRelated': tabRelated,
    'tabAll': tabAll,
};

function ProductSlider({ data }) {
    const [fetchedProducts, setFetchedProducts] = useState([]);
    const vehicleState = useSelector(state => state.vehicle);    
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Initialize with the first tab index
    const [products, setProducts] = useState(data[0].products); // Initialize with the first tab's products
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [width, setWidth] = useState(window.innerWidth); // Get the current window width
    const swiperRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchProductFromFirestore = async (productId) => {
        try {
            const warehouseRef = collection(firestore, 'gp_warehouse');
            const q = query(warehouseRef, where('ds_number_gp', '==', productId));
            const querySnapshot = await getDocs(q);
            const itemsArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            if (itemsArray.length > 0) {
                return itemsArray[0]; // Return the first product found
            } else {
                // console.error(`No product found in Firestore with ID: ${productId}`);
                return null;
            }
        } catch (error) {
            // console.error('Error fetching product from Firestore:', error);
            return null;
        }
    };

    const loadSingleProduct = (part) => {
        if (part) {
            const sellers = Object.keys(part).filter(key => configData.storeSellersWarehouses.includes(key)).map(key => {
                const sellerData = part[key];
                const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";
                
                return {
                    name: key,
                    active: sellerData.active,
                    price: sellerData.price,
                    stock: sellerData.stock,
                    price_ecommerce: sellerData.hasOwnProperty(storeprice) ? sellerData[storeprice] : sellerData.price,
                };
            });

            sellers.sort((a, b) => a.price_ecommerce - b.price_ecommerce);

            const mappedProduct = new Product(
                part.active,
                part.desc || 'No description available',
                part.ds_number,
                part.id_supplier,
                part.imhash || part.images,
                sellers,
                { height: part.altCM, width: part.largCM, weight: part.pesoR, depth: part.profCM },
                part.prop_car,
            );

            return mappedProduct;
        }
        return null;
    };

    const handleTabClick = (index) => {
        console.log('handleTabClick', index);
        setLoading(true);
        setSelectedTabIndex(index);
        setFetchedProducts([]);
        setProducts(data[index].products); // Update products based on the selected tab

        // Reset the slider position
        if (swiperRef.current) {
            swiperRef.current.slideTo(0); // Reset to the first slide
        }
    };

    
    useEffect(() => {
        let totalCount = 0; // Total count of fetched products across all fetches
        let fetchedAll = false; // Moved inside useEffect to ensure it's reset on each effect run

        const fetchProducts = async (start, end) => {
            if (fetchedAll) return;
            console.log(`Fetching products from ${start} to ${end}`);
            const slicedProducts = products.slice(start, end);
            const productsData = await Promise.all(slicedProducts.map(productId => fetchProductFromFirestore(productId)));
            const formattedProducts = productsData.map(productData => loadSingleProduct(productData)).filter(product => product !== null);

            console.log(`Fetched ${formattedProducts.length} formatted products`);

            // Filter products based on seller criteria with a counter limiter
            const limitedProducts = [];
            const existingIds = new Set(fetchedProducts.map(product => product.ds_number)); // Use a Set for quick lookup
            let localCount = 0; // Local count for this fetch operation

            for (const product of formattedProducts) {
                const validSellers = product.getSellers().filter(seller => 
                    configData.storeSellersWarehouses.includes(seller.name) && seller.stock > 0
                );

                if (validSellers.length > 0 && !existingIds.has(product.ds_number)) { // Check for duplicates
                    limitedProducts.push(product);
                    existingIds.add(product.ds_number); // Add the new product ID to the Set
                    localCount++; // Update the local count
                    totalCount++; // Update the total count

                    console.log(`Added product: ${product.ds_number}, current local count: ${localCount}, total count: ${totalCount}`);

                    if (totalCount >= 20) { // Stop if we have reached the limit of 20 products
                        console.log('Reached limit of 20 products, breaking out of loop');
                        break;
                    }
                }
            }

            // Only set fetchedProducts if totalCount is less than or equal to 20
            if (totalCount <= 20) {
                console.log(`Limited products to be set: ${limitedProducts.length}`);
                setFetchedProducts(prevFetchedProducts => [
                    ...prevFetchedProducts,
                    ...limitedProducts
                ]);
            } else {
                console.log('Not setting fetchedProducts as totalCount exceeds 20');
            }
        };

        const fetchMoreProducts = async (start, end) => {
            if (fetchedAll) return;
            setLoadingMore(true);
            console.log(`Fetching more products from ${start} to ${end}`);
            const slicedProducts = products.slice(start, end);
            const productsData = await Promise.all(slicedProducts.map(productId => fetchProductFromFirestore(productId)));
            const formattedProducts = productsData.map(productData => loadSingleProduct(productData)).filter(product => product !== null);

            console.log(`Fetched ${formattedProducts.length} formatted products for more`);

            // Filter products based on seller criteria
            const filteredProducts = formattedProducts.filter(product => {
                const validSellers = product.getSellers().filter(seller => 
                    configData.storeSellersWarehouses.includes(seller.name) && seller.stock > 0
                );
                return validSellers.length > 0; // Keep product if it has at least one valid seller
            });

            console.log(`Filtered down to ${filteredProducts.length} valid products`);

            // Limit the number of additional products to ensure total does not exceed 20
            const additionalProducts = [];
            const existingIds = new Set(fetchedProducts.map(product => product.ds_number)); // Use a Set for quick lookup
            let localCount = 0; // Local count for this fetch operation

            for (const product of filteredProducts) {
                if (totalCount >= 20) {
                    console.log('Break! finished fetching more products due to total count limit');
                    break; // Stop if we have reached the limit of 20 products
                }
                if (!existingIds.has(product.ds_number)) { // Check for duplicates
                    additionalProducts.push(product);
                    existingIds.add(product.ds_number); // Add the new product ID to the Set
                    localCount++; // Update the local count
                    totalCount++; // Update the total count
                    console.log(`Added additional product: ${product.ds_number}, current local count: ${localCount}, total count: ${totalCount}`);
                }
            }

            // Only set fetchedProducts if totalCount is less than or equal to 20
            if (totalCount <= 20) {
                console.log(`Additional products to be set: ${additionalProducts.length}`);
                setFetchedProducts(prevFetchedProducts => [
                    ...prevFetchedProducts,
                    ...additionalProducts
                ]);
            } else {
                console.log('Not setting additionalProducts as totalCount exceeds 20');
            }

            // Set fetchedAll to true if no additional products were fetched
            if (additionalProducts.length === 0) {
                fetchedAll = true;
                console.log('No additional products fetched, setting fetchedAll to true');
            }
            console.log('Finished fetching more products');
            setLoadingMore(false);
        };

        // Initial fetch
        const initialFetch = async () => {
            if (fetchedProducts.length === 0) {
                setLoading(true);
                await fetchProducts(0, 100); // Wait for fetchProducts to complete
                setLoading(false);
                // Check if we need to fetch more products after the initial fetch
                if (totalCount < 20) {
                    await fetchMoreProducts(101, 250); // Wait for fetchMoreProducts to complete
                }
                if (totalCount < 20) {
                    await fetchMoreProducts(251, 400); // Wait for fetchMoreProducts2 to complete
                }
                if (totalCount < 20) {
                    await fetchMoreProducts(401, 600); // Wait for fetchMoreProducts2 to complete
                }
                if (totalCount < 20) {
                    await fetchMoreProducts(601, 1000); // Wait for fetchMoreProducts2 to complete
                }
            }
        };

        initialFetch();
    }, [products]);

    const getSlidesPerView = () => {
        // Determine slides per view based on breakpoints
        if (width >= 1080) {
            return Math.min(fetchedProducts.length, 4); // Max 4 slides for large screens
        } else if (width >= 970) {
            return Math.min(fetchedProducts.length, 3); // Max 3 slides for medium screens
        } else if (width >= 660) {
            return Math.min(fetchedProducts.length, 2); // Max 2 slides for smaller screens
        } else {
            return Math.min(fetchedProducts.length, 1); // Max 1 slide for mobile screens
        }
    };
    
    const slidesPerView = getSlidesPerView(); // Calculate slides per view based on fetched products

    return (
        <div className='productSliderArea'>
            {data.length > 1
                ?
                    <div className='productTabArea'>
                        {data.map((tab, index) => (
                            <div key={tab.name} onClick={() => handleTabClick(index)} className={`productSliderTab ${selectedTabIndex === index ? 'activeTab' : ''}`}>
                                <img src={tabImages[tab.img]} alt={tab.name} className='productSliderTabImg'/>
                                <h2 className={`productSliderTitle ${selectedTabIndex === index ? 'activeTabTitle' : ''}`}>{tab.name}</h2>
                            </div>
                        ))}
                    </div>
                :
                    <div className='productTabArea'>
                            <img src={tabImages[data[0].img]} alt={data[0].name} className='productSliderTabImg'/>
                            <h2 className='productSliderTitle activeTabTitle'>{data[0].name}</h2>
                    </div>
            }
            <div className='productSliderContainer'>
                <Swiper
                    onSwiper={(swiper) => { swiperRef.current = swiper; }} // Set the swiper reference
                    modules={[Navigation, Pagination, Scrollbar]}
                    loop={false}
                    spaceBetween={10}
                    slidesPerView={slidesPerView}
                    navigation
                    pagination={{ clickable: true }} // Ensure this is set
                    className="productSliderSwiper"
                    // breakpoints={{
                    //     360: { slidesPerView: 1 },
                    //     660: { slidesPerView: 2 },
                    //     970: { slidesPerView: 3 },
                    //     1080: { slidesPerView: 4 },
                    // }}
                >
                    {fetchedProducts.length > 0 && !loading ? (
                        fetchedProducts.map(product => (
                            <SwiperSlide className='fadeIn'>
                                <ProductTile
                                    product={product}
                                    vehicle={vehicleState}
                                />
                            </SwiperSlide>
                        ))
                    ) : (
                        <div className='row center' style={{ height: '100px' }}>
                            <CircularProgress
                                sx={{ color: 'var(--secondColor)' }}
                            />
                        </div>
                    )}
                </Swiper>
                {/* {!loading && loadingMore &&
                    <div className='row center' style={{ height: '32px' }}>
                        <CircularProgress
                            sx={{ color: 'var(--secondColor)' }}
                        />
                    </div>
                } */}
            </div>
        </div>
    );
}

export default ProductSlider;