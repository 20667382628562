export const baraoHyundaiFilter = [
    "GPKIT3VELASHB202015",
    "273012B010",
    "273012B010",
    "252122B120",
    "2521204011",
    "1885508080",
    "1881411051",
    "281132H000",
    "392102E151",
    "391802B000",
    "2441025001",
    "263203C30A",
    "311122P000",
    "548402B200",
    "2431542200",
    "546102T000",
    "581444F000",
    "GPKIT4VELAS1884611070",
    "988502W000",
    "GPKIT6VELAS1884611070",
    "548302E200",
    "GPKIT4X1885508080",
    "971333SAA0",
    "222242G000",
    "252864A010",
    "553302S150",
    "4330247700",
    "GPKIT4VELAS1881411051",
    "983603K000",
    "287513S100",
    "285134A002",
    "2811307100",
    "58101N7A20",
    "58302N7A10",
    "577242E000",
    "3920042030",
    "273013C100",
    "3531004090",
    "319112E000",
    "2335642500",
    "5172038110",
    "583022WA00BI",
    "5841139600",
    "GPKIT3VELAS1884908085",
    "2431237500",
    "548302T000",
    "529601S000",
    "2521223721",
    "517123K050",
    "4110039350",
    "4130039350",
    "5172002000",
    "2304004100",
    "4110026021",
    "517602E000",
    "2231123700",
    "54500D3000",
    "3671042021",
    "944602E000",
    "284112B320",
    "252122G700",
    "230412B510",
    "393183C100",
    "253862S500",
    "553512E501BI",
    "553422P000",
    "219302E300",
    "210214A920BI",
    "210204A941BI",
    "252122E800",
    "495062PA20",
    "319222B900",
    "95430D9200",
    "546612T232BI",
    "546252F000",
    "546232P500",
    "552572P000",
    "213504A700",
    "3918023500",
    "47354H1010",
    "283554A800",
    "95720A7501ABP",
    "GPKITTROCAOLEOHYUNDAI16",
    "GPKITTROCAOLEOHYUNDAI16V2",
    "B971332E210",
    "971331S000",
]