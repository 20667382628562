import React, { useState, useEffect } from 'react';
import { configData } from '../Whitelabel/websitesData';
import Button from '../Common/Button';
import { getMktName } from '../Providers/Sellers';
import { CircularProgress } from '@mui/material';
import { useUser } from '../Providers/UserContext';
import { useNavigate } from 'react-router-dom';

function AdminPage() {
    const {gpuser,setUser} = useUser();
    const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(true); //SET FALSE TO REQUIRE PASSWORD
  const correctPassword = 'GoParts123';
  const [marketNames, setMarketNames] = useState({});
  const [loading, setLoading] = useState(false); // Loading state

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Negado');
    }
  };

  useEffect(() => {
    const fetchMarketNames = async () => {
      setLoading(true); // Set loading to true before fetching
      const sortedWarehouses = configData.storeSellersWarehouses.sort(); // Sort the warehouses alphabetically
      for (const warehouse of sortedWarehouses) {
        const mktName = await getMktName(warehouse);
        setMarketNames(prevNames => ({
          ...prevNames,
          [warehouse]: mktName // Update state with the fetched market name
        }));
      }
      setLoading(false); // Set loading to false after fetching all
    };

    if (isAuthenticated) {
      fetchMarketNames();
    }
  }, [isAuthenticated]);

  return (
    <div className='main fadeIn'>
        {gpuser
            ?
                <div className='pageContent center'>
                    {!isAuthenticated ? (
                    <form onSubmit={handlePasswordSubmit} className='card column' style={{ padding: '20px', gap: '10px', maxWidth: '480px' }}>
                        <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Insira a senha"
                        />
                        <Button
                        type="submit"
                        label="Acessar"
                        className='buttonColorThird smallButton'
                        />
                    </form>
                    ) : (
                    <div className='column' style={{ gap: '12px' }}>
                        <h2>{configData.storeSellersWarehouses.length > 1 ? `${configData.storeSellersWarehouses.length} sellers ativos:` : `${configData.storeSellersWarehouses.length} seller ativo:`}</h2>
                        {configData.storeSellersWarehouses
                        .sort() // This line can be removed since sorting is done in fetchMarketNames
                        .map((warehouse, index) => (
                            <p key={index}>
                            <span style={{ fontWeight: 'bold' }}>{warehouse}</span>
                            <span>
                                {!marketNames[warehouse] ? ( // Show loader if loading or market name is not yet fetched
                                <CircularProgress
                                    size={20}
                                    sx={{
                                    color: 'var(--secondColor)',
                                    marginLeft: '10px',
                                    }}
                                />
                                ) : (
                                ` - ${marketNames[warehouse] || 'N/A'}`
                                )}
                            </span>
                            </p>
                        ))}
                    </div>
                    )}
                </div>
            :
                <div className='column center' style={{ gap: '10px', paddingTop: '20px' }}>
                    <h2>Acesso restrito</h2>
                    <a className='textLink' href='/login?destination=/sellers-ativos'>Fazer login</a>
                    {/* <Button
                        label="Login"
                        className='buttonColorThird smallButton'
                        onClick={() => navigate('/login')}
                    /> */}
                </div>
        }
    </div>
  );
}

export default AdminPage;