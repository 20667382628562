import React from 'react';
import ClickOutside from '../Utilities/ClickOutside';
import { configData } from '../Whitelabel/websitesData';
import { BrandsList } from '../Utilities/BrandsList';

const WhatsappDialog = ({ onClose, onSelect }) => {
    return (
        <div className='defaultDialog fadeIn'>
            <ClickOutside onOutsideClick={onClose}>
                <div className='column cardFormContainer defaultDialogContainer' style={{ gap: '20px' }}>
                    <h2>Selecione o atendimento desejado:</h2>
                    {Object.entries(configData.multiContact).map(([key, number]) => {
                        const brandKeys = key.split('/').map(part => part.trim());
                        return (
                            <div key={key} className='whatsappOption clickArea' onClick={() => onSelect(number)}>
                                {brandKeys.map(brandKey => {
                                    const brandLogo = BrandsList[brandKey.charAt(0).toUpperCase() + brandKey.slice(1)];
                                    return brandLogo ? <img key={brandKey} src={brandLogo} alt={`${brandKey} logo`} /> : null;
                                })}
                                <span>{key}</span>
                            </div>
                        );
                    })}
                </div>
            </ClickOutside>
        </div>
    );
};

export default WhatsappDialog;