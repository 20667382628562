import { storeInfo } from './Placeholders';
import { configData } from '../Whitelabel/websitesData';

export const handleWhatsappClick = (selectedBrand, selectedModel, selectedYear, selectedVersion, orderInfo, number) => {
    const linkNumber = number || configData.whatsappNumber;
    const whatsappDefaultLink = `https://api.whatsapp.com/send/?phone=%2B${linkNumber}&text=Olá, tudo bem?`;

    const gtmWhatsappClick = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'button_whatsapp_click'
            });
        } else {
            console.warn("dataLayer is not defined");
        }
    };

    let newLink = whatsappDefaultLink;

    if (selectedVersion) {
        newLink = `${whatsappDefaultLink} Meu veículo é um ${selectedBrand} ${selectedModel} ${selectedYear} ${selectedVersion}`;
    } else if (orderInfo) {
        newLink = `${whatsappDefaultLink} Meu pedido é o de Nº ${orderInfo}`;
    }

    window.open(newLink, '_blank');
    gtmWhatsappClick();
};